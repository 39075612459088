<template>
  <div class='authDetail'>
    <table id='enterpriseCertification_detail'>
      <tr>
        <td>公司名称：</td>
        <td>{{ companyName }}</td>
      </tr>
      <tr>
        <td>统一社会信用代码：</td>
        <td>{{ companySocialCode }}</td>
      </tr>
      <tr>
        <td>公司规模：</td>
        <td>{{ getCompanyScopeName(companyScope) }}</td>
      </tr>
      <tr>
        <td>联系电话：</td>
        <td>{{ companyPhone }}</td>
      </tr>
      <tr>
        <td>公司类型：</td>
        <td>{{ getCompanyTypeName(companyType) }}</td>
      </tr>
      <tr>
        <td>公司主营：</td>
        <td>{{ companyMajor }}</td>
      </tr>
      <tr>
        <td>您的职称：</td>
        <td>{{ getCompanyPositionName(companyPosition) }}</td>
      </tr>
      <tr>
        <td valign='top'>上传资料：</td>
        <td v-if='companyLicense' align='center'>
          <a :href='companyLicense' target='_blank'>
            <img :src='companyLicense' alt='' />
          </a>
          <div>营业执照</div>
        </td>
        <td v-if='companyBusinessCard' align='center'>
          <a :href='companyBusinessCard' target='_blank'>
            <img :src='companyBusinessCard' alt='' />
          </a>
          <div>工牌/名片</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td>
          <el-button size='medium' type='primary' @click='reAudit'>重新认证</el-button>
        </td>
      </tr>
    </table>
    
    <img v-if="companyAuthStatus ==='10'"
         alt=''
         class='img__audit'
         src='./auditing.png'
         srcset='./auditing.png,./auditFail@2x.png 2x'>
    
    <img v-if="companyAuthStatus ==='20'"
         alt=''
         class='img__audit'
         src='./auditSuccess.png'
         srcset='./auditSuccess.png,./auditSuccess@2x.png 2x'>
    
    <img v-if="companyAuthStatus ==='30'"
         alt=''
         class='img__audit'
         src='./auditFail.png'
         srcset='./auditFail.png,./auditFail@2x.png 2x'>
    
    <span v-if="companyAuthStatus ==='30'" class='authDetail__authFailedReason'>
      失败原因：<br>
      {{ authFailedReason }}
    </span>
  </div>
</template>

<script>
import { companyScopeData, companyTypeData, companyPositionData } from '../data/staticData.js'

export default {
  props: [
    'companyName',
    'companySocialCode',
    'companyScope',
    'companyPhone',
    'companyType',
    'companyMajor',
    'companyPosition',
    'companyLicense',
    'companyBusinessCard',
    'companyAuthStatus',
    'authFailedReason',
    'memberId'
  ],
  
  data () {
    return {
      companyScopeData: companyScopeData(),
      companyTypeData: companyTypeData(),
      companyPositionData: companyPositionData()
    }
  },
  
  methods: {
    reAudit () {
      this.$emit('reAudit', this.memberId)
    },
    
    getCompanyScopeName (companyScope) {
      var item = this.companyScopeData.find(item => item.id == companyScope)
      if(item) return item.value
    },
    
    getCompanyTypeName (companyType) {
      var item = this.companyTypeData.find(item => item.id == companyType)
      if(item) return item.value
    },
    
    getCompanyPositionName (companyPosition) {
      var item = this.companyPositionData.find(item => item.id == companyPosition)
      if(item) return item.value
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/styles/common.scss';

#enterpriseCertification_detail {
  margin-left: rem(62px);
  margin-top: rem(48px);
  font-size: rem(14px);
  
  td {
    padding-bottom: rem(33px);
  }
  
  img {
    width: 90px;
    height: 90px;
    border-radius: rem(5px);
    border: 1px solid #ccc;
  }
}


.authDetail {
  position: relative;
}

.img__audit {
  position: absolute;
  top: 0;
  left: rem(580px)
}

.authDetail__authFailedReason {
  position: absolute;
  top: 160px;
  left: rem(580px);
  font-size: 14px;
  width: 360px;
}
</style>
