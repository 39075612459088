export function companyScopeData(){
	return [
		{id:1,value:'50人以下'},
		{id:2,value:'50-100人'},
		{id:3,value:'100人以上'},
		{id:4,value:'200人以上'}
	]
}

export function companyTypeData(){
	return [
		{id:0,value:'终端制造'},
		{id:1,value:'代工厂'},
		{id:2,value:'其它'}
	]
}

export function companyPositionData(){
	return [
		{id:0,value:'研发人员'},
		{id:1,value:'采购人员'},
		{id:2,value:'项目负责人'},
		{id:3,value:'研发负责人'}
	]
}